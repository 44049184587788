/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.woff2') format('woff2'),
       url('./assets/font/CentraNo2-Medium.woff2') format('woff2'),
       url('./assets/font/CentraNo2-Book.woff2') format('woff2');
  font-weight: 700, 500, 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar .navbar-brand a {
  text-decoration: none;
}

.fa-terminal {
    color: #fff;
    font-size: 2rem;
    font-family: 'Centra';
    font-weight: 700;
}

nav.navbar a.navbar-brand span {
    color: #fff;
    font-size: 2.5rem;
    font-family: 'Centra';
    font-weight: 700;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 3%;
  padding: 0 15px;
  font-size: 1.2rem;
  opacity: 0.75;
  font-weight: bold;
}

@media screen and (max-width: 390px) {
  .fa-terminal {
    font-size: 1.7rem;
}
  nav.navbar a.navbar-brand span {
      font-size: 2rem;
  }
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 10px;
}
.social-icon a {
    width: 3rem;
    height: 3rem;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin: 5px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  display: inline;
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px 33px;
  margin-left: 18px;
  font-size: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

@media screen and (max-width: 900px) {
  nav.navbar {
    padding: 12px 0;
  }

  nav.navbar .navbar-brand span {
    font-size: 2rem;
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 1rem;
    padding: 0 10px;
  }

  .social-icon a {
    width: 2.5rem;
    height: 2.5rem;
  }

  .navbar-text button {
    padding: 8px 30px;
    font-size: 17px;
  }
}

@media screen and (max-width: 768px) {
  .navbar-nav {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  span.navbar-text {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .social-icon a {
    margin: 0 0 0 15px;
  }
}


/************ Banner Css ************/
.banner {
  margin-top: 0px;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.webp');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 10px 15px;
  background: linear-gradient(90.21deg, rgba(131, 54, 170, 0.5) -5.91%, rgba(132, 86, 1, 0.5) 111.58%);
  border-radius: 10px;
  font-size: 1.3rem;
  margin: 50px 0 16px 0;
  display: inline-block;
}

.banner .tagline:hover {
  background: transparent;
}

.banner h1 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #e3e3e3;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner a {
  text-decoration: none;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 15px;
}
.banner img {
  animation: updown 3s linear infinite;
  max-width: 90%;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

@media screen and (max-width: 1400px) {
  .banner h1 {
    font-size: 2.3rem;
  }
  .banner p {
    color: #e3e3e3;
    font-size: 1rem;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }
}


@media screen and (max-width: 767px) {
  .banner {
    padding: 190px 0 100px 0;
  }

  .banner img {
    max-width: 80%;
    margin-top: 50px;
  }
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
  background: linear-gradient(90.21deg, rgba(131, 54, 170, 0.5) -5.91%, rgba(132, 86, 1, 0.5) 111.58%);
}
.skill-bx {
  background: rgba(21, 21, 21, 0.5);
  border-radius: 64px;
  text-align: center;
  padding: 100px 90px;
  margin: 80px auto;
  max-width: 80%;
}
.skill h2 {
  font-size: 3rem;
  font-weight: 700;
}
.skill p {
    color: #e3e3e3;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}

.skill-section{
  position: relative;
  margin: 30px 0;
}

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;  
  max-width: 80%;
  margin: 0 auto;
}

.single-chart {
  width: 30%;
  justify-content: space-evenly;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 90%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-opacity: 0.3;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@media screen and (max-width: 991px) {
  .skill-bx {
    padding: 100px 30px;
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .skill-bx {
    padding: 100px 15px;
    max-width: 90%;
  }

  .skill p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .skill p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 390px) {
  .skill-bx {
    padding: 50px 10px;
    max-width: 90%;
  }
}


.circular-chart.orange .circle {
  stroke: #f06529;
}

.circular-chart.orange:hover .circle {
  stroke: #ff7700;
}

.circular-chart.green .circle {
  stroke: #2965f1;
}

.circular-chart.green:hover .circle {
  stroke: #0099ff;
}

.circular-chart.blue .circle {
  stroke: #fef200;
}

.circular-chart.blue:hover .circle {
  stroke: #ffcc00;
}

.circular-chart.r-blue .circle {
  stroke: #61DBFB;
}

.circular-chart.r-blue:hover .circle {
  stroke: #009dff;
}

.circular-chart.j-orange .circle {
  stroke: #FB9820;
}

.circular-chart.j-orange:hover .circle {
  stroke: #ffc800;
}

.circular-chart.s-green .circle {
  stroke: #6bb016;
}

.circular-chart.s-green:hover .circle {
  stroke: #8cff00;
}

.circular-chart.p-yellow .circle {
  stroke: #FFD43B;
}

.circular-chart.p-yellow:hover .circle {
  stroke: #ffbf00;
}

.circular-chart.s-blue .circle {
  stroke: #00758F;
}

.circular-chart.s-blue:hover .circle {
  stroke: #01a8ce;
}

.circular-chart.white .circle {
  stroke: #fff;
}

.circular-chart.white:hover .circle {
  stroke: #b4b4b4;
}

.percentage {
  fill: #8b8b8b;
  font-family: sans-serif;
  font-size: 1.15em;
  text-anchor: middle;
}

.percentage:hover {
  fill: #a8a8a8;
  cursor: pointer;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: #1e1e1e;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  max-width: 80%;
}
.project .nav.nav-pills {
  max-width: 90%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(50, 50, 50);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 50%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #842a60 -5.91%, #ad5f01 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(172, 172, 172, 0.5);
}
.nav-link#projects-tabs-tab-first {
  border: none;
}
.nav-link#projects-tabs-tab-first:hover, .nav-link#projects-tabs-tab-second:hover, .nav-link#projects-tabs-tab-third:hover {
  background-color: #ad5f01;
  color: #ffffff;
}
.nav-link#projects-tabs-tab-second {
  border-left: 1px solid rgba(172, 172, 172, 0.5);
  border-right: 1px solid rgba(172, 172, 172, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: none
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  border: 5px solid rgba(255, 195, 238, 0.3);
}

.proj-imgbx:hover {
  border: 5px solid rgb(136, 136, 136);
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #842a60 -5.91%, #ad5f01 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.proj-txtx span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 10px;
}

.proj-txtx a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 700;
}

.proj-txtx a:hover {
  color: #ffd901;
}

.carousel-item img {
  max-height: 90vh;
  max-width: 90vw;
  margin: 0 auto;
}

.modal-content {
  max-width: 95vw;
  max-height: 95vh;
  margin: auto;
}

.modal-body {
  padding: 50px;
}

.modal-header .close {
  position: absolute;
  right: 50px;
  top: 50px;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

/************ Back to Top ************/
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 15px;
  background-color: #fff;
  color: #000000;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
}

.back-to-top:hover {
  background-color: #874400;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .proj-txtx h4 {
    font-size: 1rem;
    padding: 10px;
  }
  .proj-txtx span {
    padding: 0;
  }
  
  .proj-txtx a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .proj-txtx h4 {
    font-size: 0.9rem;
    padding: 0;
  }
  
  .proj-txtx span {
    font-size: 0.7rem;
    padding: 0;
  }
  
  .proj-txtx a {
    font-size: 1rem;
    margin: 10px;
  }

  .project p {
    font-size: 1rem;
  }
  
  .project .nav.nav-pills .nav-link {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 576px) {
  .proj-txtx h4 {
    font-size: 1.3rem;
    padding: 10px;
  }
  .proj-txtx span {
    font-size: 1.3rem;
    padding: 0;
  }
  
  .proj-txtx a {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 500px) {
  .proj-txtx h4 {
    font-size: 1.1rem;
    padding: 10px;
  }
  .proj-txtx span {
    font-size: 1.1rem;
    padding: 0;
  }
  
  .proj-txtx a {
    font-size: 1.1rem;
  }

  .project p {
    font-size: 0.9rem;  
  }

  .project .nav.nav-pills .nav-link {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 350px) {
  .proj-txtx h4 {
    font-size: 0.9rem;
    padding: 10px;
  }
  .proj-txtx span {
    font-size: 0.9rem;
    padding: 0;
  }
  
  .proj-txtx a {
    font-size: 0.9rem;
  }
}

/************ Contacts CSS ************/
.contact {
  background: linear-gradient(180deg, #3a3a3a -5.91%, #212121 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 90%;
}
.contact h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 30px 10px;
}

.contact form {
  max-width: 90%;
  display: block;
  margin: 0 auto;
  position: relative;
}

.contact form input,
.contact form textarea {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: none;
  background: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 1rem;
}

.contact form input:focus,
.contact form textarea:focus {
  outline: none;
}

.contact form textarea {
  resize: none;
}

.contact form button {
  border: none;
  background: #ffd901;
  color: #000;
  padding: 10px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.contact form button:hover {
  background: #f4c20d;
}

@media screen and (max-width: 768px) {
  .contact form button {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .contact h2 {
    font-size: 2rem;
  }

  .contact form button {
    font-size: 1rem;
  }
}

/************ Footer Css ************/
.footer {
  padding: 30px 0;
  background-image: url('./assets/img/footer-img.webp');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer img {
  max-width: 80%;
}

.footer .social-icon {
  margin-top: 20px;
}

.footer p {
  font-weight: 400;
  font-size: 1rem;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer .navbar-brand {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  margin-top: 20px;
}

.footer .navbar-brand a {
  text-decoration: none;
}

.footer .fa-terminal {
    color: #fff;
    font-size: 2rem;
    font-family: 'Centra';
    font-weight: 700;
    margin-top: 70px;
}

.footer .navbar-brand span {
  color: #fff;
  font-size: 2.5rem;
  font-family: 'Centra';
  font-weight: 700;
}

.footer .footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.footer .footer-links a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  font-size: 1.3rem;
  font-weight: 700;
}

.footer .footer-links a:last-child {
  margin-right: 0;
}

.footer .footer-links a:hover {
  color: #ffd901;
}

@media screen and (max-width: 576px) {
  .footer .navbar-brand {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .fa-terminal,
  .footer .navbar-brand span {
    display: inline-block;
  }

  .footer .social-icon {
    text-align: center;
    margin: 20px 0 30px 0;
  }

  .footer .footer-links,
  .footer .social-icon {
    margin-top: 20px;
  }
}




